import { getSVGs, Loading } from "./utilities/util";
import { Fullpage, FullpageOptions } from "./libraries/Fullpage";
import { link } from "fs";
declare var Swiper: any;


var swiper = new Swiper('.home-swiper-1', {
	slidesPerView: 3,
	spaceBetween: 10,
	speed: 1000,
	loop: true,
	autoplay: {
		delay: 2000,
	},
	breakpoints: {
		430: {
			slidesPerView: 3,
			spaceBetween: 10,
		},
		630: {
			slidesPerView: 4,
			spaceBetween: 10,
		},
		768: {
			slidesPerView: 5,
			spaceBetween: 20,
		},
		1200: {
			slidesPerView: 6,
			spaceBetween: 20,
		},
	},

});

var swiper2 = new Swiper('.home-swiper-2', {
	slidesPerView: 3,
	spaceBetween: 20,
	speed: 1000,
	loop: true,
	autoplay: {
		delay: 2000,
	},
	breakpoints: {
		430: {
			slidesPerView: 3,
			spaceBetween: 20,
		},
		630: {
			slidesPerView: 4,
			spaceBetween: 30,
		},
		769: {
			slidesPerView: 5,
			spaceBetween: 40,
		},
		1201: {
			slidesPerView: 6,
			spaceBetween: 44,
		},
	},
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
});

var swiper2 = new Swiper('.home-swiper-3', {
	slidesPerView: 4,
	spaceBetween: 20,
	speed: 1000,
	loop: true,
	autoplay: {
		delay: 2000,
	},
	// breakpoints: {
	// 	430: {
	// 		slidesPerView: 3,
	// 		spaceBetween: 20,
	// 	},
	// 	630: {
	// 		slidesPerView: 4,
	// 		spaceBetween: 30,
	// 	},
	// 	769: {
	// 		slidesPerView: 5,
	// 		spaceBetween: 40,
	// 	},
	// 	1201: {
	// 		slidesPerView: 6,
	// 		spaceBetween: 44,
	// 	},
	// },
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
});

var swiper2 = new Swiper('.mySwiperSlide', {
	direction: "vertical",
	slidesPerView: 5,
	freeMode: true,
	grabCursor: true,
	scrollbar: {
		el: ".swiper-scrollbar",
	},
	mousewheel: true,
});


var swiper4 = new Swiper('.mySwiperNews', {
	slidesPerView: 3,
	spaceBetween: 20,
	speed: 1000,
	breakpoints: {
		// 400: {
		// 	slidesPerView: 4,
		// 	spaceBetween: 20,
		// },
		600: {
			slidesPerView: 4,
			spaceBetween: 30,
		},
	},
	autoplay: {
		delay: 2000,
	},
});

var swiper_morning_1 = new Swiper(".mySwiperMorning-1", {
	spaceBetween: 10,
	slidesPerView: 4,
	freeMode: true,
	watchSlidesVisibility: true,
	watchSlidesProgress: true,
	direction: "horizontal",
	scrollbar: {
		el: '.swiper-scrollbar',
		draggable: true,
		dragSize: 30
	},
	breakpoints: {

		1025: {
			slidesPerView: 3,
			direction: "vertical",

		},
	},
});
var swiper_morning1 = new Swiper(".mySwiperMorning1", {
	spaceBetween: 10,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	direction: "horizontal",
	thumbs: {
		swiper: swiper_morning_1,
	},
	breakpoints: {

		1025: {
			direction: "vertical",
		},
	},
});

var swiper_noon1 = new Swiper(".mySwiperNoon1", {
	speed: 1000,
	// autoplay: {
	// 	delay: 2500,
	// 	disableOnInteraction: false,
	// },
	// loop: true,
	spaceBetween: 10,
	slidesPerView: 3,
	watchSlidesVisibility: true,
	watchSlidesProgress: true,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},

	// allowTouchMove: false,
	paginationClickable: true,
});

var myCardSlide = new Swiper(".myCardSlide", {

	direction: "vertical",
	slidesPerView: "auto",
	freeMode: true,
	mousewheel: true,
	scrollbar: {
		el: '.swiper-scrollbar',
		dragSize: 30,
		// snapOnRelease: true
	},
});


function clickSlideLeftToRight() {
	var slideLeft = document.querySelectorAll('.your-moments-noon__pic .mySwiperNoon1 .swiper-wrapper img');
	console.log(slideLeft);

	slideLeft.forEach(function (itemSlide) {
		itemSlide.addEventListener('click', function () {
			var rightSlide = document.querySelector('.your-moments-noon__pic .img-right img');
			rightSlide.setAttribute('src', itemSlide.getAttribute('src'));
		})
	})
}


var swiper_afternoon_1 = new Swiper(".mySwiperAfternoon-1", {
	loop: true,
	spaceBetween: 10,
	slidesPerView: 4,
	// freeMode: true,
	watchSlidesVisibility: true,
	watchSlidesProgress: true,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
});

var swiper_afternoon1 = new Swiper(".mySwiperAfternoon1", {
	spaceBetween: 10,
	slidesPerView: 1,

	thumbs: {
		swiper: swiper_afternoon_1,
	},
});



var swiper_night1 = new Swiper(".mySwiperNight1", {
	grabCursor: true,
	loop: true,
	spaceBetween: 10,
	slidesPerView: 2.1,
	centeredSlides: true,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
});


var swiper_timetable1 = new Swiper(".myTimeTable", {
	grabCursor: true,
	direction: "vertical",
	spaceBetween: 0,
	slidesPerView: 3,
	freeMode: true,
	mousewheel: true,
	scrollbar: {
		el: '.swiper-scrollbar',
		draggable: true,
		dragSize: 30
	},
	breakpoints: {
		600: {
			slidesPerView: 4,
		},
		800: {
			slidesPerView: 5,
		}
	},
});

var swiper_newsevent1 = new Swiper(".myNewsEvent", {
	grabCursor: true,
	direction: "vertical",
	spaceBetween: 0,
	slidesPerView: 3,
	freeMode: true,
	mousewheel: true,
	scrollbar: {
		el: '.swiper-scrollbar',
		draggable: true,
		dragSize: 30
	},
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
});
var swiper_Services1 = new Swiper(".myServiceSwipe", {
	spaceBetween: 20,
	slidesPerView: 2,
	freeMode: true,
	mousewheel: true,
	breakpoints: {
		600: {

			spaceBetween: 20,
		},
		769: {
			slidesPerView: 3,
			spaceBetween: 10,
		},
	},
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
});
var swiper_Services_Class1 = new Swiper(".mySwiperService-Class", {
	spaceBetween: 20,
	slidesPerView: 2,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
});
var swiper_Leasing1 = new Swiper(".mySwiperLeasing", {
	spaceBetween: 30,
	direction: "vertical",
	slidesPerView: 2,
	scrollbar: {
		el: '.swiper-scrollbar',
		draggable: true,
		dragSize: 30
	},
	breakpoints: {
		600: {

			spaceBetween: 35,
		},
		769: {
			spaceBetween: 104,
		},
	},
});



const scrollTop = () => {
	var btn_scroll = document.querySelector(".backtoTop-overlay")
	if (btn_scroll) {
		btn_scroll.addEventListener("click", () => {
			topFunction()
		})

	}
	function topFunction() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}
}

const onscrollHeader = () => {
	const header = document.querySelector("header .header__wrapper")
	const headerNav = document.querySelector("header .nav-Bar")
	window.onscroll = function () { scrollFunction() };

	function scrollFunction() {
		if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
			header.classList.add("active");
			headerNav.classList.add("nav-scroll");
		} else {
			header.classList.remove("active");
			headerNav.classList.remove("nav-scroll");
		}
	}
}
const hamburgerChange = () => {
	const btnHamburger = document.querySelector('.hamburger-Navbar')
	const headerNav = document.querySelector("header .nav-Bar")
	if (btnHamburger) {
		btnHamburger.addEventListener('click', () => {
			btnHamburger.classList.toggle('change')
			headerNav.classList.toggle('nav-active')
			document.body.classList.toggle('body-overflow')
		})
	}
}


function timeConvert(n: any) {
	var number = n.split(":")
	var format = (number[0] > 12) ? "pm" : "am"
	var time = number[0] + ":" + number[1] + " " + format
	return time
}

console.log(timeConvert("08:34"));


const changeActiveHeader = () => {
	const currentLocation = location.href
	const menuHeader = document.querySelectorAll(".header__wrapper li a")

	// console.log(menuHeader1.origin)
	for (var i = 0; i < menuHeader.length; i++) {
		if ((menuHeader[i] as HTMLLinkElement).href === currentLocation) {
			menuHeader[i].classList.add("active-link")
		}
	}
}
const changeActiveFooter = () => {
	const currentLocation = location.href
	const menuHeader = document.querySelectorAll(".footer__wrapper li a")

	// console.log(menuHeader1.origin)
	for (var i = 0; i < menuHeader.length; i++) {
		if ((menuHeader[i] as HTMLLinkElement).href === currentLocation) {
			menuHeader[i].parentElement.classList.add("active")
		}
	}
}

const openCard = () => {
	const ourservices = document.querySelector('.ourservices-membership')
	if (ourservices) {
		const btnCardOpen = document.querySelectorAll('.ourservices-membership__container .box-content')
		btnCardOpen.forEach(elememt => elememt.addEventListener('click', () => {
			console.log(elememt)
			const btnCardOpen = elememt.querySelector('.footer .btn')
			const variable = btnCardOpen.querySelector('span').innerHTML.split(":")
			var cardColor = variable[0]
			var cardName = variable[1]
			console.log("map-get($mau, " + cardColor + ")")
			const card_overlay = document.getElementById('overlay-card') as HTMLDivElement
			card_overlay.style.background = cardColor
			const nameCard = card_overlay.querySelector('.title')
			nameCard.innerHTML = cardName
			card_overlay.classList.add("active")
			document.body.classList.add('body-overflow')

			card_overlay
		}))
		const btnCardClose = document.querySelector('#overlay-card .btn-close')
		btnCardClose.addEventListener('click', () => {
			const card_overlay = document.getElementById('overlay-card')
			card_overlay.classList.remove("active")
			document.body.classList.remove('body-overflow')
		})
	}
}


document.addEventListener("DOMContentLoaded", async () => {
	getSVGs(".svg");
	// Loading();
	scrollTop()
	onscrollHeader()
	hamburgerChange()
	// slideTimetableHover()
	// changeActiveHeader()
	openCard()
	clickSlideLeftToRight();
	// changeActiveFooter()
});
window.addEventListener('load', (e) => {

})
